<template>
  <c-box
    width="100%"
    mx="auto"
    :height="['100%', 'auto']"
    background-color="#FFFFFF"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['none','16px']"
    :padding="['1rem', '1.5rem']"
    :min-height="['calc(100vh - 62px)', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        padding="20px 90px"
        :paths="['target_harian', 'materi_harian'].includes(item.type)
          ?
            [
              {
                label: 'Dashboard',
                href: { name: 'client.index' } },
              {
                label: 'Panduan Diet',
                href: {
                  name: 'client.content-challenge',
                  query: {
                    type: item.type
                  }
                }
              },
              ...(item.monthGroup ? [
                {
                  label: `Bulan ${item.monthGroup}`,
                  href: {
                    name: 'client.content-challenge',
                    query: {
                      type: item.type,
                      month: item.monthGroup
                    }
                  }
                }] : []),
              {
                label: `Detail ${_getContentType}`,
                isCurrent: true,
              },
            ]
          :
            [
              {
                label: 'Dashboard',
                href: { name: 'client.index' }
              },
              {
                label: 'Panduan Diet',
                href: { name: 'client.content-challenge' }
              },
              {
                label: `Detail ${_getContentType}`,
                isCurrent: true,
              },
            ]"
      />
    </Portal>
    
    <c-flex
      v-if="isContentNotFound"
      max-width="992px"
      :height="['calc(100vh - 150px)', 'calc(74vh - 64px)']"
      :flex-direction="['column', 'column','row']"
      align-items="center"
      justify-content="center"
      margin-inline="auto"
      gap="30px"
    >
      <c-image
        src="https://ik.imagekit.io/dietela/pwa_webp/404/undraw_location_search_re_ttoj%201.webp?updatedAt=1719548493470"
        :height="['175px', '350px']"
        flex-shrink="0"
      />
      <c-flex
        :flex-grow="['0', '1']"
        flex-direction="column"
        color="neutral.balck"
        :align-items="['center', 'center', 'flex-start']"
      >
        <c-text
          :font-size="['22px', '32px']"
          font-weight="600"
        >
          Oops! 
        </c-text>
        <c-text
          :font-size="['22px', '32px']"
          font-weight="600"
          margin-bottom="16px"
        >
          Halaman Tidak Ditemukan
        </c-text>
        <c-text
          :font-size="['14px', '20px']"
          font-weight="500"
          margin-bottom="20px"
          :text-align="['center', 'center', 'left']"
          :padding-inline="['24px', '24px', '0px']"
        >
          Sepertinya kamu tersesat, halaman yang kamu cari tidak tersedia
        </c-text>

        <BaseButton
          size="medium"
          width="100%"
          display="flex"
          :max-width="['250px', '400px']"
          height="50px"
          border-radius="30px"
          gap="12px"
          align-items="center"
          justify-content="center"
          
          @click="$router.replace({ name: 'client.index' })"
        >
          Kembali ke Beranda
          <c-box
            width="20px"
            height="20px"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-arrow-right-2.svg')"
              height="100%"
              width="100%"
              fill="#FFF"
            />
          </c-box>
        </BaseButton>
      </c-flex>
    </c-flex>

    <c-box
      v-else
    >
      <c-box
        v-if="isRoleNutritionist"
        display="flex"
        margin-bottom="48px"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
          width="100%"
          text-align="center"
        >
          Detail Konten
        </BaseText>
        <c-box
          cursor="pointer"
          @click="shareToWhatsapp"
        >
          <c-image
            :src="require('@/assets/logos_whatsapp-icon.svg')"
            :width="['30px', '45px']"
            :height="['30px', '45px']"
            ml="auto"
          />
        </c-box>
      </c-box>

      <c-box
        v-if="!['sm', 'md'].includes(currentBreakpoint) && ['client','admin'].includes(authRole)"
        max-width="780px"
        margin-inline="auto"
      >
        <c-button
          variant="ghost"
          py="0"
          px="8px"
          display="flex"
          align-items="center"
          gap="1rem"
          @click="onBack"
        >
          <c-image
            w="24px"
            h="24px"
            object-fit="cover"
            :src="require('@/assets/icon-chevron-left.svg')"
          />
          <c-text
            font-size="16px"
            color="primary.400" 
            font-weight="500"
          >
            Kembali
          </c-text>
        </c-button>
      </c-box>

      <c-box
        v-if="isLoadingContent"
        max-width="700px"
        :margin-top="['0px', '16px']"
        margin-inline="auto"
      >
        <c-box
          w="80%"
          :h="['30px', '42px']"
          border-radius="8px"
          margin-inline="auto"
          overflow="hidden"
          margin-bottom="16px"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
          />
        </c-box>
        <c-box
          w="120px"
          :h="['24px', '27px']"
          border-radius="8px"
          margin-inline="auto"
          overflow="hidden"
          margin-bottom="6px"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
          />
        </c-box>
        <c-box
          w="100px"
          :h="['18px', '21px']"
          border-radius="8px"
          margin-inline="auto"
          overflow="hidden"
          margin-bottom="16px"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
          />
        </c-box>
        
        <c-box
          w="100%"
          :h="['60vh', '40vh']"
          border-radius="8px"
          margin-inline="auto"
          overflow="hidden"
          margin-bottom="6px"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
          />
        </c-box>
      </c-box>

      <c-box 
        v-else
        max-width="700px"
        :margin-top="['0px', '16px']"
        margin-inline="auto"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
          text-align="center"
        >
          {{ item.title || '-' }}
        </BaseText>

        <c-box margin-top="16px">
          <BaseText
            v-for="categoryName in _getCategoryName"
            :key="categoryName"
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
            text-align="center"
          >
            {{ categoryName || '-' }}
          </BaseText>
        </c-box>

        <BaseText
          size-mobile="12px"
          size-desktop="14px-2"
          text-align="center"
        >
          {{ item.creator || '-' }}
        </BaseText>

        <BaseText
          v-if="['materi_harian', 'target_harian'].includes(item.type)"
          size-mobile="12px"
          size-desktop="14px-2"
          text-align="center"
          color="gray"
        >
          Konten Bulan {{ item.monthGroup || '-' }} Hari ke-{{ item.showOnDay || '-' }}
        </BaseText>

        <c-box
          v-for="(section, sectionIdx) in item.contentSection"
          :key="section.id"
          margin-top="16px"
        >
          <!-- SECTION YOUTUBE -->
          <c-form-control
            v-if="section.contentSectionType.sectionName === 'YouTube'"
            :margin-top="sectionIdx === 0 ? '' : '30px'"
          >
            <c-flex
              justify-content="center"
              align-items="center"
              width="100%"
            >
              <c-box
                border-radius="12px"
                overflow="hidden"
                width="100%"
                aspect-ratio="7/4"
                :max-width="['100%', '640px']"
              >
                <youtube
                  player-width="100%"
                  :video-id="youtubeParser(section.url)"
                />
              </c-box>
            </c-flex>
            <BaseText
              margin-top="6px"
              color="gray"
              size-mobile="12px"
              size-desktop="14px-2"
              text-align="center"
            >
              {{ section.description }}
            </BaseText>
          </c-form-control>

          <!-- SECTION DESCRIPTION -->
          <c-form-control
            v-else-if="section.contentSectionType.sectionName === 'Description'"
            :margin-top="sectionIdx === 0 ? '' : '30px'"
          >
            <c-text
              color="superDarkGray.900"
              font-size="18px"
              line-height="27px"
              word-break="break-word"
              class="ck-content"
              v-html="section.description"
            />
          </c-form-control>

          <!-- SECTION IMAGE -->
          <c-form-control
            v-if="section.contentSectionType.sectionName === 'Gambar'"
            :margin-top="sectionIdx === 0 ? '' : '30px'"
          >
            <c-flex
              direction="column"
              justify-content="center"
              align-items="center"
            >
              <c-box
                width="100%"
                max-width="640px"
              >
                <c-image
                  :src="getPhotoProgram(section.url)"
                  alt="file program"
                  mx="auto"
                  border-radius="6px"
                />
              </c-box>
              <BaseText
                color="gray"
                size-mobile="12px"
                size-desktop="14px-2"
                text-align="center"
                margin-top="6px"
              >
                {{ section.description }}
              </BaseText>
            </c-flex>
          </c-form-control>

          <!-- SECTION UPLOAD_FILE -->
          <c-form-control
            v-if="section.contentSectionType.sectionName === 'Document/File'"
            :margin-top="sectionIdx === 0 ? '' : '30px'"
          >
            <c-flex align-items="center">
              <c-flex
                align-items="center"
                justify-content="center"
                h="100px"
                w="100px"
                mr="20px"
                bg="#F4CC4619"
                border-radius="12px"
              >
                <c-image
                  w="50px"
                  h="50px"
                  :src="require('@/assets/icon-folder.svg')"
                  alt="icon-folder"
                />
              </c-flex>
              <c-flex
                justify-content="center"
                align-items="baseline"
                flex-dir="column"
                flex="1"
              >
                <BaseText
                  size-mobile="14px-2"
                  size-desktop="16px"
                >
                  {{ section.description ? JSON.parse(section.description).fileName : '-' }}
                </BaseText>
                <BaseText
                  color="gray"
                  size-mobile="14px-2"
                  size-desktop="16px"
                >
                  {{ section.description ? formatBytes(JSON.parse(section.description).fileSize): '-' }}
                </BaseText>
              </c-flex>
              <c-flex
                align-items="center"
                justify-content="center"
                margin-left="auto"
                border-radius="12px"
                width="55px"
                height="55px"
                :_hover="{
                  backgroundColor: isLoadingDownloadFile[section.contentSectionTypeId] ? '' : '#e3f0ef',
                }"
                @click="download(section)"
              >
                <inline-svg
                  :src="require('@/assets/icons/icon-download.svg')"
                  :fill="isLoadingDownloadFile[section.contentSectionTypeId] ? '#888' : '#008C81'"
                />
              </c-flex>
            </c-flex>
          </c-form-control>

          <!-- SECTION CAROUSEL -->
          <c-box
            v-if="section.contentSectionType.sectionName === 'Carousel'"
            width="100%"
            max-width="800px"
            position="relative"
            margin="auto"
            :margin-top="sectionIdx === 0 ? '' : '30px'"
          >
            <VueSlickCarousel
              v-chakra="{
                '.slick-prev': {
                  width: '50px',
                  height: '50px',
                  left: '0',
                  backgroundImage: require('@/assets/icon_next_left.svg'),
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100%',
                  zIndex: '1',
                  '::before': {
                    display: 'none',
                  },
                  ':focus': {
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    width: '50px',
                    height: '50px',
                    backgroundImage: require('@/assets/icon_next_left.svg'),
                  },
                  ':hover': {
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    width: '50px',
                    height: '50px',
                    backgroundImage: require('@/assets/icon_next_left.svg'),
                  },
                  '@media(max-width: 767px)': {
                    display: 'none !important',
                  },
                },
                '.slick-next': {
                  right: '0',
                  width: '50px',
                  height: '50px',
                  backgroundImage: require('@/assets/icon_next_right.svg'),
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100%',
                  zIndex: '1',
                  '::before': {
                    display: 'none',
                  },
                  ':focus': {
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    width: '50px',
                    height: '50px',
                    backgroundImage: require('@/assets/icon_next_right.svg'),
                  },
                  ':hover': {
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    width: '50px',
                    height: '50px',
                    backgroundImage: require('@/assets/icon_next_right.svg'),
                  },
                  '@media(max-width: 767px)': {
                    display: 'none !important',
                  },
                },
                '.slick-dots': {
                  bottom: '8px',
                  '@media(max-width: 767px)': {
                    bottom: '15px',
                  },
                  li: {
                    '@media(max-width: 767px)': {
                      margin: '0 3px',
                    },
                    button: {
                      '::before': {
                        fontSize: '12px',
                        color: '#C4C4C4',
                        opacity: '1',
                        '@media(max-width: 767px)': {
                          fontSize: '10px',
                        },
                      },
                    },
                    '&.slick-active': {
                      button: {
                        '::before': {
                          color: '#008C81',
                          opacity: '1',
                        },
                      },
                    },
                  },
                },
                '.slick-slide': {
                  padding: '0 80px',
                  transform: 'scale(1)',
                  transitionProperty: 'all',
                  transitionDuration: '300ms',
                  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                  '@media(max-width: 767px)': {
                    padding: '0px',
                    transform: 'none',
                  },
                },
              }"
              v-bind="{
                arrows: true,
                dots: true,
                dotsClass: 'slick-dots custom-dot-class',
                edgeFriction: 0.35,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
              }"
            >
              <c-box
                v-for="(photo, index) in section.contentCarouselImage"
                :key="'banner-' + index"
                as="a"
                target="_blank"
                pos="relative"
                class="item"
                width="100%"
                mx="auto"
                :mt="['0px', '30px']"
                :mb="['0px', '70px']"
              >
                <c-image
                  :src="photo.url"
                  :srcset="photo.url"
                  alt="dietela"
                  width="100%"
                  :box-shadow="['none', '0px 4px 8px rgba(0, 0, 0, 0.2)']"
                />
              </c-box>
            </VueSlickCarousel>
            <BaseText
              color="gray"
              size-mobile="12px"
              size-desktop="14px-2"
              position="absolute"
              text-align="center"
              bottom="40px"
              left="50%"
              transform="translateX(-50%)"
            >
              {{ section.description }}
            </BaseText>
          </c-box>
        </c-box>

        <c-box
          v-if="!isRoleNutritionist"
        >
          <c-flex
            v-if="_getDoneReadText"
            gap="8px"
            justify-content="center"
            margin-top="16px"
          >
            <BaseButton
              width="100%"
              max-width="500px"
              height="50px"
              border-radius="30px"
              :visibility="!!_getDoneReadText ? 'visible' : 'hidden'"
              :disabled="!!item?.isRead"
              @click="doneRead"
            >
              {{ _getDoneReadText }}
            </BaseButton>
            <BaseButton
              border-radius="12px"
              padding="0"
              min-width="50px"
              height="50px"
              :is-loading="isLoadingFavorite"
              loading-text=""
              :is-loading-text="false"
              @click="onClickFavorite"
            >
              <c-spinner
                v-if="isLoadingFavorite"
                thickness="4px"
                speed="0.65s"
                empty-color="green.200"
                color="#FFF"
                size="xl"
              />
              <c-box
                v-else
              >
                <inline-svg
                  v-if="item?.isFavorite"
                  :src="require('@/assets/icons/icon-love.svg')"
                  height="32"
                  width="32"
                  fill="#D32737"
                />
                <inline-svg
                  v-else
                  :src="require('@/assets/icons/icon-love.svg')"
                  height="32"
                  width="32"
                  fill="white"
                />
              </c-box>
            </BaseButton>
          </c-flex>
          <c-flex
            v-else
            gap="8px"
            justify-content="center"
            margin-top="16px"
          >
            <BaseButton
              width="100%"
              max-width="500px"
              height="50px"
              border-radius="30px"
              :color="item?.isFavorite ? 'danger' : 'primary'"
              :is-loading="isLoadingFavorite"
              :loading-text="item?.isFavorite ? 'Hapus Favorit' : 'Tambah Favorit'"
              @click="onClickFavorite"
            >
              {{ item?.isFavorite ? 'Hapus Favorit' : 'Tambah Favorit' }}
            </BaseButton>
          </c-flex>
        </c-box>
        <c-flex
          v-else
          gap="8px"
          justify-content="center"
          margin-top="16px"
        >
          <BaseButton
            width="100%"
            max-width="500px"
            height="50px"
            border-radius="30px"
            @click="onBack"
          >
            Kembali
          </BaseButton>
          <BaseButton
            border-radius="12px"
            padding="0"
            min-width="50px"
            height="50px"
            :is-loading="isLoadingFavorite"
            loading-text=""
            :is-loading-text="false"
            @click="onClickFavorite"
          >
            <c-spinner
              v-if="isLoadingFavorite"
              thickness="4px"
              speed="0.65s"
              empty-color="green.200"
              color="#FFF"
              size="xl"
            />
            <c-box
              v-else
            >
              <inline-svg
                v-if="item?.isFavorite"
                :src="require('@/assets/icons/icon-love.svg')"
                height="32"
                width="32"
                fill="#D32737"
              />
              <inline-svg
                v-else
                :src="require('@/assets/icons/icon-love.svg')"
                height="32"
                width="32"
                fill="white"
              />
            </c-box>
          </BaseButton>
        </c-flex>
      </c-box>
    </c-box>

    <!-- MODAL -->
    <BaseModal
      :is-open="showModalGetProgram"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="160px"
            object-fit="cover"
            :src="require('@/assets/images/ecommerce-campaign.svg')"
            alt="image get program"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
            text-align="center"
          >
            Yuk beli program dulu <br> untuk membaca materi selengkapnya
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            @click="$router.push({name: 'client.select-program'})"
          >
            Pilih Program
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="showModalReadContent"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="160px"
            object-fit="cover"
            :src="require('@/assets/images/success-image.svg')"
            alt="image get program"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          flex-direction="column"
          justify-content="center"
          padding="30px 24px"
        >
          <BaseText
            size-mobile="20px"
            size-desktop="28px"
            color="primary.400"
            text-align="center"
          >
            <c-box v-html="titleModalRead" />
          </BaseText>
          <BaseText
            v-if="descriptionModalRead"
            size-mobile="14px-2"
            size-desktop="16px"
            color="#555"
            text-align="center"
            margin-top="1rem"
          >
            {{ descriptionModalRead }}
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-box
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
          margin-inline="auto"
        >
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            :min-width="['150px', '200px']"
            @click="showModalReadContent = false"
          >
            Oke
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import { CBox, CFlex, CImage, CFormControl, CText, CButton, CSpinner } from '@chakra-ui/vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import BaseText from '@/components/elements/base-text.vue'
import { youtubeParser } from '@/utils/youtubeParser'
import { reqGetDetailContent } from '@/requests/dietela-api/client/content-challenge/get-detail-content'
import BaseButton from '@/components/elements/base-button.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'
import EventBus from '@/utils/event-bus'
import { reqGetDetailContent as reqGetDetailContentNutri } from '@/requests/dietela-api/nutritionist/content-challenge/get-detail-content'

export default {
  name: 'AdminCoachingChallengeDetail',
  components: {
    BaseModal,
    BaseButton,
    BaseText,
    CBox,
    CFlex,
    CImage,
    CText,
    CFormControl,
    VueSlickCarousel,
    BreadcrumbPath,
    CButton,
    CSpinner,
  },
  mixins: [generalMixin, mixinsCheckBreakpoints()],
  data() {
    return {
      isLoadingContent: false,
      isLoadingDownloadFile: {},
      showModalGetProgram: false,
      showModalReadContent: false,
      item: {},
      isLoadingFavorite: false,
      isLoadingRead: false,
      titleModalRead: 'Yeay! Materi selesai dibaca',
      descriptionModalRead: 'Sayangnya kamu tidak mendapatkan poin karena sudah melebihi batas hadiah poin harian. Coba besok lagi untuk mendapatkan poin tambahan',
      isContentNotFound: false,
    }
  },
  computed: {
    ...mapGetters({
      axios: 'axios',
      userId: 'auth/userId',
      meta: 'contentChallenge/meta',
    }),
    _getCategoryName() {
      return this.item?.categoryName?.map((category) => {
        const subCategoryString = category?.subCategories?.reduce((a, b, index) => {
          if (index === 0) return a + b.subCategoryName
          return `${a}, ${b.subCategoryName}`
        }, '')
        return `${category?.categoryName}${subCategoryString ? `: ${subCategoryString}` : ''}`
      })
    },
    _getContentType() {
      switch (this.item.type) {
        case 'tips_gizi':
          return 'Tips Gizi'
        case 'materi_harian':
          return 'Materi Harian'
        case 'target_harian':
          return 'Target Harian'
        default:
          return ''
      }
    },
    _getDoneReadText() {
      if (this.isRoleNutritionist) {
        return 'Kembali'
      }
      switch (this.item.type) {
        case 'materi_harian':
          return this.item?.isRead ? 'Sudah Dibaca' : 'Selesai Membaca'
        case 'target_harian':
          return 'Target Selesai'
        default:
          return ''
      }
    },
    id() {
      return this.$route.params.id
    },
  },
  async mounted() {
    this.setShowTotalPoinUser(false)
    if (this.isClient) {
      this.getTotalUserPoin()
    }

    this.isLoadingContent = true
    let res = null
    if (this.isRoleNutritionist) {
      res = await reqGetDetailContentNutri(
        this.$store.getters.axios,
        this.id,
      ).catch((err) => {
        console.log('Error get content:', err?.response)
        if (err?.response?.status === 404 || err?.response?.data?.message === 'Content not found') {
          this.isContentNotFound = true
        }
      })
    } else if (this.isClient) {
      res = await reqGetDetailContent(
        this.$store.getters.axios,
        this.id,
      ).catch((err) => {
        console.log('Error get content:', err?.response)
        if (err?.response?.status === 404 || err?.response?.data?.message === 'Content not found') {
          this.isContentNotFound = true
        }
      })
    }
    this.isLoadingContent = false

    const content = res?.data?.data

    if (content?.isReachableContent === 1 && content?.isAvailableToShare === 0) {
      this.$router.replace({
        name: 'client.content-challenge',
        query: {
          type: content?.type,
          month: content?.monthGroup,
          contentId: content?.id,
        },
      }).catch(() => {})
      return
    } else if (content?.isReachableContent === 0) {
      this.$router.replace({
        name: 'client.content-challenge',
        query: {
          type: content?.type,
          month: content?.monthGroup,
          contentId: content?.id,
        },
      }).catch(() => {})
      return
    }

    this.item = res?.data?.data || {}

    switch (this.item.type) {
      case 'tips_gizi':
        return EventBus.$emit('HEADER_TITLE', 'Tips Gizi')
      case 'materi_harian':
        return EventBus.$emit('HEADER_TITLE', 'Materi Harian')
      case 'target_harian':
        return EventBus.$emit('HEADER_TITLE', 'Target Harian')
      default:
        return EventBus.$emit('HEADER_TITLE', '')
    }
  },
  methods: {
    ...mapMutations({
      setCustomHeaderText: 'clients/setCustomHeaderText',
      setShowTotalPoinUser: 'clients/setShowTotalPoinUser',
      setTotalPoinUser: 'clients/setTotalPoinUser',
    }),
    ...mapActions({
      getTotalUserPoin: 'clients/getTotalUserPoin',
      addContentFavorite: 'contentChallenge/addContentFavorite',
      deleteContentFavorite: 'contentChallenge/deleteContentFavorite',
      readContent: 'contentChallenge/readContent',
    }),
    youtubeParser,
    download(section) {
      if (this.item?.clientType === 'non-intensive') {
        this.showModalGetProgram = true
        return
      }

      if (this.isLoadingDownloadFile[section.contentSectionTypeId]) return
      this.isLoadingDownloadFile[section.contentSectionTypeId] = true
      fetch(section.url)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = JSON.parse(section.description).fileName
          link.click()
        })
        .catch(() => {
          this.$toast({
            title: 'Failed',
            description: 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        }).
        finally(() => {
          this.isLoadingDownloadFile[section.contentSectionTypeId] = false
        })
    },
    doneRead() {
      if (this.isRoleNutritionist) {
        this.onBack()
        return
      }
      if (this.item?.clientType === 'non-intensive') {
        this.showModalGetProgram = true
        return
      }
      this.isLoadingRead = true
      this.readContent(this.id)
        .then((res) => {
          if (res?.data?.isAlreadyMax) {
            this.titleModalRead = `Yeay! ${this.item?.type === 'target_harian' ? 'Target selesai' : 'Materi selesai dibaca'}`
            this.descriptionModalRead = 'Sayangnya kamu tidak mendapatkan poin karena sudah melebihi batas hadiah poin harian. Coba besok lagi untuk mendapatkan poin tambahan'
          } else {
            this.titleModalRead = `Yeay! ${this.item?.type === 'target_harian' ? 'Target selesai' : 'Materi selesai dibaca'},<br> kamu mendapatkan reward ${res?.data?.poinReward} poin!`
            this.descriptionModalRead = ''
          }
          this.item = {
            ...this.item,
            isRead: 1,
          }
          this.getTotalUserPoin()
          // this.setTotalPoinUser(res?.data?.totalPoin)
          this.showModalReadContent = true
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data?.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
        .finally(() => {
          this.isLoadingRead = false
        })
    },
    onClickFavorite() {
      if (this.item?.clientType === 'non-intensive') {
        this.showModalGetProgram = true
        return
      }
      this.isLoadingFavorite = true
      const payload = {
        contentAndChallengeId: this.id,
      }
      if (this.item?.isFavorite) {
        this.deleteContentFavorite(payload)
          .then(() => {
            this.$toast({
              title: 'Berhasil',
              description: 'Konten terhapus dari favorit',
              status: 'success',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
            this.item = {
              ...this.item,
              isFavorite: !this.item?.isFavorite,
            }
          })
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data.message ??'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .finally(() => {
            this.isLoadingFavorite = false
          })
      } else {
        this.addContentFavorite(payload)
          .then(() => {
            this.$toast({
              title: 'Berhasil',
              description: 'Konten ditambah ke favorit',
              status: 'success',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
            this.item = {
              ...this.item,
              isFavorite: !this.item?.isFavorite,
            }
          })
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data.message ??'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .finally(() => {
            this.isLoadingFavorite = false
          })
      }
    },
    onBack() {
      this.$router.back()
    },
    shareToWhatsapp() {
      window.open(
          `https://wa.me/?text=${encodeURIComponent(`Baca lebih lanjut tentang ini yuk :\n\n*${this.item?.title?.trim()}*\n\nKategori: ${this._getCategoryName?.join(', ')}\n\n${window.location.href}`)}`
          , '_blank')
    },
  },
}
</script>
<style scoped>
table {
  width: 100%;
  border: thin solid #c4c4c4;
}
table tr th {
  border-left: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  padding: 4px 8px;
}
table tr td {
  border-left: thin solid #c4c4c4;
  border-bottom: thin solid #c4c4c4;
  padding: 4px 8px;
}
</style>